
const config: IConfig = {
  serve: 'http://rap2backend.1212test.com',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
